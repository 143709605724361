/* retailer home page */
.filter-selector .ant-select-selector {
  /* border: 1px solid #000 !important; */
  background-color: white !important;
  /* height: 34px !important; */
  padding: 0px 12px !important;
  border-radius: 4px !important;
  color: black !important;
  text-align: left !important;
  /* width: 116px !important; */
  display: flex !important;
  align-items: center !important;
}
.filter-selector .ant-select-selection-item {
  color: black !important;
}

.filter-selector .ant-select-selection-placeholder {
  color: gray !important;
}

/* Payment modal pop up */
.payment-method-button {
  color: black;
  border: 1px solid #000;
  border-radius: 5px;
  height: 45px;
}

.payment-method-input-box {
  height: 50px;
  padding: 0px 16px;
  border-radius: 4px;
}

.bank-selector .ant-select-selector {
  background-color: white !important;
  height: 50px !important;
  padding: 0px 16px !important;
  border-radius: 4px !important;
  color: black !important;
  text-align: left !important;
}


.bank-selector .ant-select-selection-item {
  color: black !important;
  display: flex;
  align-items: center;
  text-align: left;
}

.bank-selector .ant-select-selection-placeholder {
  color: gray !important;
  display: flex;
  align-items: center;
  text-align: left;
}


/* statement page  */
.statement-table-form table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  padding: 10px;
  font-weight: 300;
  font-size: 14px;
}

th {
  width: 25%;
  color: #5B5B5B;
}

.print-statement-button {
  width: 100%;
  border: none;
  border-radius: 5px;
  height: 40px;
  font-weight: 600;
  background-color: #333F48;
  color: white;
}


.logout-button {
  width: 10%;
  position: absolute;
  right: 24px;
  top: 50px;
}

.qrCode-button {
  width: 100%;
  border: none;
  border-radius: 5px;
  height: 40px;
  font-weight: 600;
  background-color: #30EA03;
  color: white;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.custom-header .ant-table-thead > tr > th {
  background-color: #f0f0f0;
  color: #000; 
  text-align: center;
}

.bold-text {
  font-weight:bold;
}

/* Retailer's show QR page */
.back-to-homepage-btn {
  width: 80%;
  height: 45px;
  background-color: black;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


@media (min-width: 600px) {
  .logout-button {
    width: 50px;
    position: absolute;
    left: 60%;
    bottom: 50%;

  }
}