@media (min-width: 200px) {
	.layout-position {
		bottom: 120px;
		position: relative;
		max-height: 10vh;
	}

	.img-position {
		width: 25%;
		margin-top: 40px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	.h1 {
		padding-top: 20px;
		font-family: 'Hero New', arial;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0em;
	}

	/* .slick-dots li button:after{
    width: 100px;
  } */
}

/* =========================================== */
@media (min-width: 250px) {
	.layout-position {
		bottom: 120px;
		position: relative;
		max-height: 10vh;
	}

	.img-position {
		width: 25%;
		margin-top: 40px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	.h1 {
		padding-top: 20px;
		font-family: 'Hero New', arial;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0em;
	}

	/* .slick-dots li button:after{
    width: 100px;
  } */
}

/* =========================================== */
@media (min-width: 300px) {
	.layout-position {
		bottom: 120px;
		position: relative;
		max-height: 10vh;
	}

	.background-height {
		max-height: 10vh;
	}

	.clickBtn {
		width: 85%;
		background-color: transparent;
		margin: auto;
		position: absolute;
		top: 260px;
		left: 20px;
		height: 75px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	.h1 {
		padding-top: 20px;
		font-family: 'Hero New', arial;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0em;
	}

	/* .slick-dots li button:after{
    width: 100px;
  } */
}

/* =========================================== */
@media (min-width: 350px) {
	.layout-position {
		position: relative;
		bottom: 170px;
	}

	.getStarted-card {
		width: 100%;
		background-color: black;
		height: 50vh;
		border-top-right-radius: 50px;
		color: white;
	}

	.img-position {
		width: 25%;
		margin-top: 40px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	.h1 {
		padding-top: 20px;
		font-family: 'Hero New', arial;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0em;
	}
	/* 
  .slick-dots li button:before{
    color: transparent !important;

  }

  .slick-dots li button:after{
    width: 100px;
  } */
}

/* =========================================== */
@media (min-width: 400px) {
	.layout-position {
		position: relative;
		bottom: 165px;
	}

	.clickBtn {
		width: 85%;
		background-color: transparent;
		margin: auto;
		position: relative;
		top: 400px;
		height: 60px;
	}

	.text-position {
		width: 80%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
	}

	.h1 {
		padding-left: 20px;
		padding-top: 20px;
	}
}

/* ======================================= */
@media (min-width: 1000px) {
	.layout-position {
		position: relative;
		bottom: 165px;
	}

	img {
		width: 50%;
	}

	.logo-position {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 1;
		width: 30%;
		margin-top: 10px;
	}
}

/* =========================================== */
/* img {
  width: 100%;
} */

.form-input-login {
	width: 80%;
	background-color: transparent;
	border: 1px solid white;
	color: white;
	/* height: 40px; */
}

.form-input-login::placeholder,
.form-input-login::-webkit-input-placeholder,
.form-input-login input::-webkit-input-placeholder {
	color: rgb(130, 129, 129);
}

.logo-position {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 1;
	margin-top: 10px;
}

.login-card {
	width: 100%;
	background-color: black;
	/* height: 55vh; */
	border-top-right-radius: 50px;
	color: white;
	padding-bottom: 5vh;
}

.card {
	width: 100%;
	background-color: black;
	/* height: 50vh; */
	border-top-right-radius: 50px;
	color: white;
	padding-bottom: 5vh;
}
