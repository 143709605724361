.loading-retailer {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.ret-login {
    display: flex;
    height: auto;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.ret-login-logo-container {
    text-align: center;
    margin-top: 60px;
}

.ret-login-logo {
    width: 100%;
}

.ret-partner-program-logo {
    width: 60%;
}

.ret-login-pic {
    width: 80%;
}

.ret-login-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ret-login-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20%;
}

.ret-login-subtitle {
    /* font-family: lightFont; */
    font-size: 16px;
    text-align: center;
    margin-top: 5%;
    color: #1B1C1A;
}

.ret-login-input-container {
    margin-top: 15%;
    text-align: center;
}

.ret-verify-container {
    width: 80%;
}

.ret-verify-loading-btn {
    border: none;
    color: orange;
    font-size: 55px
}

.ret-form-input-login {
    height: 50px;
    background-color: #E8E8E8;
    /* border-radius: 15px; */
    font-size: 15px;
    width: 80%;
    font-weight: 600;
}

.ret-form-submit-btn {
    background: linear-gradient(270deg,
            #f2b81b 2.61%,
            #f0c31b 2.62%,
            #e35c19 59.88%,
            #e21f19 96.6%) !important;
    /* border-radius: 8px; */
    color: white;
    height: 40px;
    border: none;
    font-weight: 600;
    width: 100% !important;
    font-size: medium;
}

.ret-verify-modal-btn {
    margin-top: 5%;
    background: #0EB7;
    /* border-radius: 8px; */
    color: white;
    height: 40px;
    border: none;
    font-weight: bold;
    width: 40%;
}

.ret-resend-otp {
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}

.ret-bold-font {
    margin-top: 20px;
    font-size: 31px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
}

.ret-submission-history-card {
    background-color: white;
    border-radius: 8px;
}

.ant-picker-panel-container {
    width: 350px !important;
}

.ant-picker-panel-container>.ant-picker-panel-layout>.ant-picker-presets {
    width: 110px !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
    min-width: auto !important;
    max-width: none !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 0 !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel table {
    table-layout: unset;
    width: 243px !important;
}

.ant-picker-dropdown .ant-picker-header {
    width: 245px !important;
}


.ant-picker-dropdown .ant-picker-today-btn {
    display: none;
}

.ant-picker-dropdown .ant-picker-header-view {
    flex: auto;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li:last-child {
    font-weight: bold;
    position: absolute;
    bottom: 2%;
    left: 10px;
    color: #30EA03;
}

.ant-input,
.ant-picker,
.ant-select,
.ant-select-selector,
.ant-select-open {
    height: 37px;
    /* border: 1px solid black; */
}

.ant-picker-header-super-next-btn {
    visibility: visible !important;
}

.ant-picker-header-next-btn {
    visibility: visible !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #30EA03 !important;
    border-radius: 50px !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul {
    height: 0;
    flex: auto;
    list-style: none;
    overflow: auto;
    margin: 0;
    padding: 8px;
    border: none;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    display: none;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color: #D7FFCD !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border: none;
}