@import url('./Fonts/Gotham/style.css');
@import url('./Fonts/Hero-New/style.css');

.App {
    /* min-height: 100vh; */
    margin: 0 auto !important;
    max-width: 500px;
    background-color: white;
}

.ml-1 {
    margin-left: 10px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.ml-4 {
    margin-left: 40px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.mt-4 {
    margin-top: 40px;
}

.mt-5 {
    margin-top: 50px;
}

.t-white {
    color: white;
}

.t-black {
    color: black
}

.t-green {
    color: #30E700;
}

.t-pink {
    color: #FA00FF;
}

.text-deco-none {
    text-decoration: none;
}

.text-deco-underline {
    text-decoration: underline;
}

.d-flex {
    display: flex;
}

.d-inline {
    display: inline;
}

.w-90 {
    width: 90%;
}

.w-10 {
    width: 10%;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.text-center {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.mr-3 {
    margin-right: 30px;
}

.mr-4 {
    margin-right: 40px;
}

.fontSize-12 {
    font-size: 12px;
}

.fontSize-14 {
    font-size: 14px;
}

.fontSize-16 {
    font-size: 16px;
}

.fontSize-18 {
    font-size: 18px;
}

.fontSize-20 {
    font-size: 20px;
}

.fontSize-24 {
    font-size: 24px;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer;
}

.my-1 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.justify-content-center {
    justify-content: center;
}

.user-container {
    /* width: 90%; */
    background-color: black;
    height: 170px;
    border-radius: 25px 25px 25px 25px;
    color: white;
}

.ant-progress .ant-progress-inner {
    background: white;
    width: 100%;
}

.footer-container {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.upload-receipt-foooter {
    font-size: 11.5px;
}

.img-upload-receipt {
    width: 80%;
}

.upload-receipt-foooter {
    position: relative;
    top: 3px;
}

@media (min-width: 500px) {

    .footer-container {
        width: 500px;
        background-color: black;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
    }

    .haleon-logo {
        width: 100%;
    }

}



@media (min-width : 1000px) {

    .haleon-logo {
        width: 110px;
    }

    .img-upload-receipt {
        width: 80%;
    }

}


.ad-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-container-for-stores {
    position: relative;
    float: right;
    bottom: 230px;
    left: 10px;
}

/* Help Center page */
.ad-container-help-center {
    position: absolute;
    /* position: absolute; */
    margin-left: 260px;
    margin-top: -330px;
}

@media (min-width: 1200px) {

    .ad-container-help-center {
        position: absolute;
        margin-left: 420px;
        bottom: 200px;
    }


}

/* ==================================== */
.ant-carousel {
    height: 40vh;
}

.coming-soon-button {
    width: 90%;
    background: #30E700 !important;
    border: none;
    border-radius: 5px;
    height: 35px;
}


.ant-drawer .ant-drawer-header .overflow-btn {
    border-bottom: none;
}

.ant-drawer .ant-drawer-body {
    padding: 0px 0px 0px 35px;
    overflow: auto;
}

.ant-drawer .ant-drawer-title {
    justify-content: center;
    display: flex;
}

.overflow-btn .ant-drawer-close {
    position: absolute;
    top: 20px;
    right: 0;
}

.minimized-panel {
    width: 90%;
    margin: 5px 20px;
}


.admin-portal-dropdown .ant-select-selector {
    background-color: white !important;
    border-radius: 5px !important;
    height: 5vh !important;
    color: black !important;
    text-align: left;
}

.admin-portal-dropdown .ant-select-selection-item {
    color: black !important;
}

.admin-portal-dropdown .ant-select-selection-placeholder {
    color: gray !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=text] {
    -moz-appearance: textfield;
}

.whatsapp-img {
    width: 18%;
    z-index: 1;
    position: absolute;
    left: 5px;
    margin-top: 10px;
}


@media (min-width : 600px) {
    .whatsapp-img {
        width: 15%;
        z-index: 1;
        position: absolute;
        left: 5px;
        margin-top: 10px;
    }
}

#my-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.charts-css {
    width: 100%;
}

.column.show-heading {
    margin-bottom: 20px;
}

.column.show-heading caption {
    font-weight: bold;
    margin-bottom: 10px;
}

.column.show-heading td {
    padding: 5px;
    text-align: center;
}

.column.show-heading .data {
    display: block;
    background-color: #007bff;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.charts-css tr {
    display: flex;
    width: 100%;
    justify-content: center;
}

.charts-css td {
    display: flex;
    justify-content: center;
}

.charts-css td span.data {
    background-color: #007bff;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.legend {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.legend li {
    margin-bottom: 5px;
}

.legend li span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

/* Example of defining custom column sizes */
.charts-css td:nth-child(1) {
    --size: 0.2;
}

.charts-css td:nth-child(2) {
    --size: 0.4;
}

.charts-css td:nth-child(3) {
    --size: 0.6;
}


.reset-preset {
    position: relative !important;
    top: 30px !important;
}



.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 12px;
}