.qr-homepage-container {
    width: auto;
    height: 150px;
    color: white;
    position: relative;
    border-radius: 27px;
    border: 4px solid #000;
    background: #30EA03;
    box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin: 30px auto;
  }

.qr-homepage-font {
    color: black;
    text-align: center;
    font-size: 35px;
    line-height: 41px;
    font-weight: 900;
}

.receipt-upload-container {
    width: 300px;
    height: 150px;
    color: white;
    position: relative;
    border-radius: 8px;
    background: #30EA03;
    box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.receipt-sample-container {
    display: flex;
    height: 380px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 3px solid #30E700;
    background: #FFF;
    justify-content: center;
}

.upload-receipt-font {
    font-size: 16px;
    font-weight: 700;
}

.after-upload-receipt-container {
    display: flex;
    justify-content: center;
    height: 150px;
  }

.summary-details-container {
    height: 230px;
    border-radius: 10px;
    border: 3px solid #30E700;
    display: flex;
    align-items: center;
    text-align: left;
    line-height: 30px;
}

.summary-details-font {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.summary-details-font p {
    margin-bottom: 10px;
  }

.summary-close-btn {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 24px;
    height: 24px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.qr-scanner-container {
    width: 80%;
  }

.qr-scanner-font {
  color: var(--main-black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.store-details-dropdown .ant-select-selector {
  background-color: white !important;
  border-radius: 5px !important;
  height: 5vh !important;   
  color: gray !important;
  display: flex;
  align-items: center;
}

.store-details-dropdown .ant-select-selection-item {
  color: gray !important;
}

.store-details-dropdown .ant-select-selection-placeholder {
  color: gray !important;
}

.store-details-dropdown {
  color: white;
}
