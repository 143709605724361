.ant-tabs-nav {
	display: none !important;
}

.form-item_input {
	border: 2px solid #ddd !important;
}

.ant-select-selection-search {
	color: black !important;
}

.ant-select-selection-item {
	color: black !important;
}

.ant-select-selection-placeholder {
	color: black !important;
}
